export const EntrataLeaseJsonToCSV = (
  JsonArray,
  buildingName,
  buildingId,
  buildingLink,
  buildingCode
) => {
  const JsonFields = [
    'firstName',
    'lastName',
    'fullName',
    'unitNumber',
    'email',
    'phoneNumber',
    'leaseStartDate',
    'leaseEndDate',
    'buildingName',
    'buildingId',
    'buildingLink',
    'buildingCode',
    'Occupant Type',
    'Relationship'
  ];
  let csvStr = `${JsonFields.join(',')}\n`;

  JsonArray.lease.forEach((element) => {
    element.customers.customer.forEach((customer) => {
      const firstName =
        typeof customer.firstName === 'undefined' ? '' : customer.firstName;
      const lastName =
        typeof customer.lastName === 'undefined' ? '' : customer.lastName;
      const unitNumber =
        typeof element.unitNumberSpace === 'undefined'
          ? ''
          : element.unitNumberSpace;
      const email =
        typeof customer.addresses.address.email === 'undefined'
          ? ''
          : customer.addresses.address.email;
      const phoneNumber =
        typeof customer.addresses.phone === 'undefined'
          ? ''
          : customer.addresses.phone.phoneNumber;
      const leaseStartDate =
        typeof element.leaseIntervals.leaseInterval[0].startDate === 'undefined'
          ? ''
          : element.leaseIntervals.leaseInterval[0].startDate;
      const leaseEndDate =
        typeof element.leaseIntervals.leaseInterval[0].endDate === 'undefined'
          ? ''
          : element.leaseIntervals.leaseInterval[0].endDate;
      const moveInDate =
        typeof element.leaseIntervals.leaseInterval[0].startDate === 'undefined'
          ? ''
          : element.leaseIntervals.leaseInterval[0].startDate; // should be a search on Lease Activities for eventType="Actual Move In"

      const occupantType = customer.customerType || 'Other';
      const relationshipName = customer.relationshipName || 'Other';
      csvStr += `${firstName},${lastName},${firstName} ${lastName},${unitNumber},${email},${phoneNumber},${leaseStartDate},${leaseEndDate},${buildingName},${buildingId},${buildingLink},${buildingCode},${occupantType},${relationshipName}\n`;
    });
  });
  return csvStr;
};

export const YardiLeaseJsonToCSV = (
  residents,
  buildingName,
  buildingId,
  buildingLink,
  buildingCode
) => {
  const JsonFields = [
    'firstName',
    'lastName',
    'fullName',
    'unitNumber',
    'email',
    'phoneNumber',
    'occupantType',
    'primaryResident',
    'leaseStartDate',
    'leaseEndDate',
    'buildingName',
    'buildingId',
    'buildingLink',
    'buildingCode',
    'status'
  ];
  let csvStr = `${JsonFields.join(',')}\n`;

  residents.forEach((customer) => {
    const firstName = customer.firstName?.replace(',', ' ') ?? '';
    const lastName = customer.lastName?.replace(',', ' ') ?? '';
    const unitNumber = customer.unit?.replace(',', ' ') ?? '';
    const email = customer.email ?? '';
    const phoneNumber = customer.phone ?? '';
    const leaseStartDate = customer.details?.LeaseFromDate ?? '';
    const leaseEndDate = customer.details?.LeaseToDate ?? '';
    const moveInDate = customer.details?.MoveInDate ?? '';
    const occupantType = customer.occupantType ?? '';
    const primaryResident = customer.primaryResident ?? '';
    const status = customer.yardiStatus;

    csvStr += `${firstName},${lastName},${firstName} ${lastName},${unitNumber},${email}, ${phoneNumber}, ${occupantType}, ${primaryResident},${leaseStartDate},${leaseEndDate},${buildingName},${buildingId},${buildingLink},${buildingCode}, ${status}\n`;
  });
  return csvStr;
};

export default EntrataLeaseJsonToCSV;
