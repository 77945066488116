import { limitPerPage } from '~/helpers/constants';
import {
  convertKeyValueToFormData,
  convertKeyValueToFormDataV2
} from '~/helpers/helpers';
import { emperor, api } from '~/services';
import {
  Building,
  BuildingDashboardConfigResponse,
  IBuildingAddress,
  IBuildingEmailDetails,
  IBuildingEmailGroup,
  IBuildingEmailRole,
  UpdateBuildingDashboardConfig
} from '~/types/building';
import { GatheringFormType } from '~/types/gathering';
import { Comment } from '~/types/comment';
import { AxiosResponse, CancelToken } from 'axios';
import { TenantInfo } from '~/containers/RentRoll/TenantRentRollTable';
import { IBuildingHelloData } from '~/types/helloData';
import {
  IBuildingTile,
  IBuildingTileForm
} from '~/containers/MyBuildingTiles/interfaces';

export type BuildingWithUserMissingTenantKey = {
  uuid: string;
  name: string;
  totalUsersMissingTenantKey: number;
};

export const fetchBuildingsRequest = async (cancelToken?: CancelToken) => {
  return await emperor.get('/v2/user/buildings', { cancelToken });
};

export const fetchGatheringsRequest = async (
  buildingId: string,
  view?: string,
  sort?: string
) => {
  return await emperor.get(`v2/building/${buildingId}/gatherings`, {
    params: {
      sort: sort ?? 'startTime ASC',
      cancelled: false,
      view
    }
  });
};

export const createGatheringRequest = async (gathering: GatheringFormType) => {
  return await emperor.post(
    '/groupevent',
    convertKeyValueToFormDataV2(gathering)
  );
};

export const fetchBuildingGroups = async (buildingId: string) => {
  return await emperor.get('/group', {
    params: { building: [buildingId], active: true }
  });
};

export const fetchBuildingEventTypes = async (buildingId: string) => {
  return await emperor.get('/event-types', {
    params: { building: buildingId }
  });
};

export const deleteGatheringRequest = async (gatheringId: string) => {
  return await emperor.delete(`/groupevent/${gatheringId}`);
};

export const cancelRecurringGatheringRequest = async (gatheringId: string) => {
  return await emperor.patch(
    `/groupevent/${gatheringId}/cancel-recurring-event`
  );
};

export const updateGathering = async (gathering: GatheringFormType) => {
  return await emperor.patch(
    `/groupevent/${gathering.uuid}`,
    convertKeyValueToFormDataV2(gathering)
  );
};

export const fetchBuildingStats = async (buildingId: string) => {
  return await emperor.get(`/building/${buildingId}/stats`);
};

export const fetchBuildingDetails = async (
  buildingId: string,
  min?: boolean
) => {
  const params = min && { returnData: 'min' };
  return await emperor.get(`v2/user/building/${buildingId}`, {
    params
  });
};

export const getBuildingsWithTotalUsersMissingTenantKey = async () => {
  return await emperor.get<{ buildings: BuildingWithUserMissingTenantKey[] }>(
    '/resident-management/buildings/total-users-missing-tenantkey'
  );
};

export const fetchSentimentComments = async (
  sentiment: string,
  buildingId: string,
  page: number
): Promise<Comment[]> => {
  const params = {
    page,
    sentiment
  };
  const res = await emperor.get(
    `/building/${buildingId}/comments-by-sentiment`,
    {
      params
    }
  );

  return res.data;
};

export const updateBuilding = async (
  buildingPatch: Object,
  buildingId: string
) => {
  const data = convertKeyValueToFormData(buildingPatch);
  return emperor.patch(`/building/${buildingId}`, data);
};

export const updateBuildingUnits = async (
  buildingPatch: Object,
  buildingId: string
) => {
  return emperor.patch(`/building/${buildingId}/unit`, buildingPatch);
};

export const setCommenterRequest = async (
  buildingId: string,
  userEmail: string,
  type: string
) => {
  return emperor.post(`/building/${buildingId}/assign-commenter`, {
    buildingId,
    userEmail,
    type
  });
};

export const fetchBuildingDashboardConfig = async (
  buildingId: string
): Promise<AxiosResponse<BuildingDashboardConfigResponse>> => {
  const res = await emperor.get(`/buildingdashboardconfig/${buildingId}`);
  return res;
};

export const fetchBuildingMerchants = async (buildingId: string) => {
  return await emperor.get('/merchant', {
    params: { building: buildingId, returnData: 'min' }
  });
};

export const fetchGatheringFeedback = async (gatheringId: string) => {
  return await emperor.get('/v2/gatheringFeedback', {
    params: {
      gathering: gatheringId
    }
  });
};

export const fetchCommentsRequest = async (building: string, page: number) => {
  return await emperor.get(`/globalcomment/dash`, {
    params: { page, limit: limitPerPage, building }
  });
};

export const fetchCommentThreadRequest = async (
  id: string
): Promise<{ status: number; data: Comment[] }> => {
  return await emperor.get(`/groupcomment/${id}/thread`);
};

export const fetchGroupEventCommentThreadRequest = async (
  id: string
): Promise<{ status: number; data: Comment[] }> => {
  return await emperor.get(`/groupeventcomment/${id}/thread`);
};

export const fetchCommentsWithTimeFilterRequest = async (
  building: string,
  timeFilter: number
) => {
  return await emperor.get(`/globalcomment/dash`, {
    params: { timeFilter, building }
  });
};

export const fetchUserCountByBuilding = async (buildingId: string) => {
  return await emperor.get(`/building/${buildingId}/resident-count`);
};

export const fetchPositiveResidentsRequest = async (buildingId: string) => {
  return await api.get(
    `/resident-review/building/${buildingId}/eligible-residents`
  );
};

export const removeResidentFormResidentsTabRequest = async (data: {
  user: string;
  removalReason: string;
  removalTable: string;
  bypassCheck?: boolean;
}) => {
  return await emperor.post(`resident-removed`, data);
};

export const deactivateResidentByBuilding = async (data: {
  building: string;
}) => {
  return await emperor.post(`building/deactivate-residents`, data);
};

export const deactivateBuilding = async (data: { building: string }) => {
  return await emperor.patch(`building/${data.building}/deactivate`, data);
};

export const updateDashboardConfig = async (
  buildingId: string,
  config: UpdateBuildingDashboardConfig
) => {
  return await emperor.patch(`buildingdashboardconfig/${buildingId}`, {
    config
  });
};

export const addGatheringTemplates = async (buildingId: string) => {
  return await emperor.post(`building/clone-gathering-templates`, {
    id: buildingId
  });
};

export const removeGatheringTemplates = async (buildingId: string) => {
  return await emperor.delete(
    `building/clone-gathering-templates/${buildingId}`
  );
};

export const sendInviteRequest = async (user: string) => {
  return await emperor.post('invitation', { user });
};

export const fetchResidentsWithReviewInvitationRequest = async (
  buildingId: string,
  page: number
) => {
  return await emperor.get(`/building/${buildingId}/invitation`, {
    params: { limit: limitPerPage, page }
  });
};

export const fetchGatheringComments = async (
  gatheringId: string,
  gatheringDate?: string
) => {
  return await emperor.get(`/groupeventcomment`, {
    params: {
      groupEvent: gatheringId,
      ...(gatheringDate && {
        interestedDate: gatheringDate
      })
    }
  });
};

export const fetchCommentSentimentStats = async (building: string) => {
  return await emperor.get(`/building/${building}/comment-sentiment`, {
    params: { timeFilter: 30 }
  });
};

export const postCreateBuilding = async (building: FormData) => {
  return await emperor.post(`/building`, building);
};

export const patchEditBuilding = async (
  buildingId: string,
  building: FormData
) => {
  return await emperor.patch(`/building/${buildingId}`, building);
};

export const fetchUsersNotLinkedWithTenant = async (buildingId: string) => {
  return await emperor.get(
    `/v2/building/${buildingId}/users/missing-tenant-info`
  );
};

export const searchTenant = async (buildingId: string, searchText: string) => {
  return emperor.get(
    `/buildings/${buildingId}/tenant-search?search=${searchText}`
  );
};

export const bindUserWithTenant = async (
  buildingId: string,
  matches: { userId: string; tenant: TenantInfo }[]
) => {
  return emperor.patch(
    `/buildings/${buildingId}/manually-bind-tenant-with-user`,
    {
      matches
    }
  );
};

export const fetchBuildingDetailsById = async (
  buildingId: string
): Promise<AxiosResponse<Building>> => {
  return await emperor.get('/building/find-one-building', {
    params: { buildingId }
  });
};

export const getBuildingEmailRoles = async (): Promise<
  AxiosResponse<IBuildingEmailRole[]>
> => {
  return await emperor.get('/building/get-building-email-roles');
};

export const getBuildingEmailGroups = async (): Promise<
  AxiosResponse<IBuildingEmailGroup[]>
> => {
  return await emperor.get('/building/get-building-email-groups');
};

export const getBuildingEmailDetails = async (
  buildingUuid: string
): Promise<AxiosResponse<IBuildingEmailDetails>> => {
  return await emperor.get('/building/get-email-details', {
    params: { buildingUuid }
  });
};

export const postBuildingEmailDetails = async (
  buildingUuid: string,
  buildingDetails: Partial<IBuildingEmailDetails>
): Promise<AxiosResponse<IBuildingEmailDetails>> => {
  return await emperor.post('/building/post-email-details', {
    buildingUuid,
    ...buildingDetails
  });
};

export const putBuildingAddress = async (
  buildingId: string,
  address: IBuildingAddress
): Promise<AxiosResponse<IBuildingAddress>> => {
  return await emperor.put('/building/address', { ...address, buildingId });
};

export const getBuildingAddress = async (
  buildingId: string
): Promise<AxiosResponse<IBuildingAddress>> => {
  return await emperor.get('/building/address', { params: { buildingId } });
};

export const patchBuildingEmailGroups = async (
  buildingUuid: string,
  buildingDetails: Partial<IBuildingEmailDetails>
): Promise<AxiosResponse<IBuildingEmailDetails>> => {
  return await emperor.patch('/building/patch-email-groups', {
    buildingUuid,
    ...buildingDetails
  });
};

export const postEnableHelloData = async (
  buildingId: string,
  enableHelloData: boolean
): Promise<AxiosResponse<void>> => {
  return await emperor.post('/building/enable-hello-data', {
    enableHelloData,
    buildingId
  });
};

export const getBuildingHelloData = async (
  buildingId: string
): Promise<AxiosResponse<IBuildingHelloData>> => {
  return await emperor.get('/building/get-hello-data', {
    params: { buildingId }
  });
};

export const getBuildingTiles = async (
  buildingId: string
): Promise<AxiosResponse<IBuildingTile[]>> => {
  return await emperor.get('/building/building-tiles', {
    params: { buildingId }
  });
};

export const putBuildingTile = async (
  formData: FormData
): Promise<AxiosResponse<IBuildingTile>> => {
  return await emperor.put('/building/building-tile', formData);
};

export const deleteBuildingTile = async (
  tileUuid: string
): Promise<AxiosResponse<IBuildingTile>> => {
  return await emperor.delete('/building/building-tile', {
    params: { tileUuid: tileUuid }
  });
};

export const sortBuildingTile = async (
  byTileIdSortOrder: Record<string, number>
): Promise<AxiosResponse<void>> => {
  return await emperor.patch('/building/sort-building-tiles', {
    byTileidSortOrder: JSON.stringify(byTileIdSortOrder)
  });
};
